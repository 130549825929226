import Api from '@/services/Api'

export default {
  create(params) {
    return Api().post('/api/contacts', params)
  },

  list() {
    return Api().get('/api/contacts')
  },
  delete(id) {
    return Api().delete('/api/contacts/' + id)
  },
  getAttachment(id) {
    return Api().get(`/api/contacts/${id}/attachment`)
  },
}
