import Api from '@/services/Api'

export default {
  create(params) {
    return Api().post('/api/senders', params)
  },
  list() {
    return Api().get('/api/senders')
  },
  delete(id) {
    return Api().delete('/api/senders/' + id)
  },
  checkAuth(phoneNumber, authCode) {
    return Api().get(`/api/senders/checkAuth?phoneNumber=${phoneNumber}&authCode=${authCode}`)
  },
}
